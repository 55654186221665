/*
 * These are the scripts that will be executed before the jquery javascript 
 * library is loaded.  This was implemented to facilitate iPhone usage over
 * even the slow EDGE network.  This is a particular issue since the iPhone
 * also won't cache any objects over 25KB (decompressed size - reference:
 * http://yuiblog.com/blog/2008/02/06/iphone-cacheability/).  The
 * uncompressed size of the total JS for the project is around 100K as 
 * of October 2008.
*/


/********************  Region Selector ****************************/
window.onload = function() { // Wait for the page to load.
  var input = document.getElementById('region_id');
  if (input) {
    input.onchange = function(){
      var regionForm = document.getElementById('changeRegionForm');
      regionForm.submit();
    }
  }
};
